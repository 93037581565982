
















import { Board, TestBoardStateCode } from '@/includes/types/Board.types'

import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component({})
export default class BotStateAlert extends Vue {

  @Prop() boardSettings!:Board

  @Emit()
  onStateAlertClick() {}

  get botStateErrorAlertMessage():string {
    if (this.boardSettings.test_state === TestBoardStateCode.BotDomainError) {
      return this.$t('bot_dashboard_domain_warning_title', [ this.boardSettings.title ]).toString()
    } if (this.boardSettings.test_state === TestBoardStateCode.ApiError) {
      return this.$t('bot_dashboard_api_error_warning_title', [ this.boardSettings.title ]).toString()
    }

    return ''
  }

  get botStateErrorDescription():string {
    if (this.boardSettings.test_state === TestBoardStateCode.BotDomainError) {
      return this.$t('bot_dashboard_domain_warning_description', [ this.boardSettings.title ]).toString()
    } if (this.boardSettings.test_state === TestBoardStateCode.ApiError) {
      return this.$t('bot_dashboard_api_error_warning_description', [ this.boardSettings.title ]).toString()
    }

    return ''
  }
}
