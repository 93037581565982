
























































































































































































































import { BoardsApi } from '@/includes/Api/Boards.api'
import { InputSetups } from '@/mixins/input-setups'
import { Board, TestBoardStateCode } from '@/includes/types/Board.types'
import TestStateInfoModal from '@/components/TestStateInfoModal.vue'
import TestStateMixin from '@/mixins/TestStateMixin'
import { errorNotification } from '@/includes/services/NotificationService'
import SetBotTokenToBoard from '@/components/SetBotTokenToBoard/SetBotTokenToBoard.vue'
import Admins from '@/views/board-settings/admins.vue'
import BoardLicenseInformation from '@/components/BotLicenseInformation.vue'
import BotStateAlert from '@/components/BotSettings/BotStateAlert.vue'
import { setPostBoardConfig } from '@/includes/logic/Bot/BotConfig'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { timezoneList } from 'piramis-base-components/src/logic/timizone-list'
import LanguagePicker from 'piramis-base-components/src/components/LanguagePicker.vue'
import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { atSignedLogin } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'

import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component({
  components: {
    BotStateAlert,
    HighlightAnchor,
    BoardLicenseInformation,
    Admins,
    PageTitle,
    LanguagePicker,
    TestStateInfoModal,
    AListCustom,
    AListCustomItem,
    SetBotTokenToBoard,
    ConfigField,
  },
  data() {
    return {
      snakeCase,
      EntityTypes,
      TestBoardStateCode,
      atSignedLogin
    }
  },
})
export default class CommonSettings extends Mixins<UseFields, InputSetups, TestStateMixin, ClipboardCopyMixin>(UseFields, InputSetups, TestStateMixin, ClipboardCopyMixin) {

  boardSettings: Board | null = null

  testStateLoading = false

  botChanging = false

  @Watch('$store.state.boardsState.activeBoard', { deep: true })
  onStateChange() {
    this.fetchConfig()
  }

  deleteBoard(): void {
    this.$confirm({
      title: this.$t('remove_board_warning').toString(),
      content: this.$t('remove_board_warning_message').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      centered: true,
      onOk: () => {
        this.botChanging = true

        BoardsApi.deleteBoard('tg', {
          board_key: this.$store.state.boardsState.activeBoard!.board,
        })
          .then(() => {
            this.$router.push({
              name: 'Boards'
            })
          })
          .catch(errorNotification)
      }
    })
  }

  getTimezoneList(): Promise<any> {
    return Promise.resolve(timezoneList)
  }

  saveBoardConfig(): void {
    this.isLoading = true

    if (this.boardSettings) {
      const { board, config, timezone, lang } = this.boardSettings

      setPostBoardConfig(board, { config, timezone, lang })
        .then(res => {
          if (res) {
            this.fetchConfig()
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }

  fetchConfig(): void {
    const { activeBoard } = this.$store.state.boardsState

    if (activeBoard) {
      this.boardSettings = cloneDeep(activeBoard)
    }
  }

  async postBoardTestState(): Promise<void> {
    this.buttonDisabled = true
    this.testStateLoading = true

    try {
      await BoardsApi.testPostBoard('tg', { board_key: this.$store.getters.activeBoard!.board })
      await this.$store.dispatch('getBoardConfig', this.$store.state.boardsState.activeBoard!.board)
      this.fetchConfig()

      this.buttonDisabled = false

    } catch (error: any) {
      if (error.response.status === 429) {
        this.buttonDisabled = true

        setTimeout(() => this.buttonDisabled = false, 15000)
        errorNotification('Попробуйте проверить статус позже')
      } else {
        errorNotification(error)
        this.buttonDisabled = false
      }
    } finally {
      this.testStateLoading = false
    }
  }

  created(): void {
    this.fetchConfig()

    if (this.boardSettings && this.boardSettings.test_state === TestBoardStateCode.None) {
      this.postBoardTestState()
    }
  }
}
