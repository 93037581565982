var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-by-name"},[_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'userName',
          'validation': 'required',
        }
      }}}),_c('div',{staticClass:"flex justify-end mr-2"},[_c('a-button',{staticClass:"btn-success",attrs:{"disabled":!_vm.userName},on:{"click":_vm.addAdminUsername}},[_vm._v(" "+_vm._s(_vm.$t('add_admin_submit_username'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }