































import { InputSetups } from '@/mixins/input-setups'
import { BoardsApi } from '@/includes/Api/Boards.api'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { BoardAdmin, BoardAdminPermissions } from '@/includes/types/Board.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'

import Component from 'vue-class-component'
import { Emit, Mixins, Prop } from 'vue-property-decorator'

@Component
export default class AdminByLink extends Mixins<UseFields, InputSetups, ClipboardCopyMixin>(UseFields, InputSetups, ClipboardCopyMixin) {

  @Prop() boardId!: string

  @Prop({ type: Object }) permissions!: BoardAdminPermissions | null

  @Prop({ type: Array, default: () => [] }) channelIds!: Array<number>

  adminLink: string | null = null

  getLinkButtonLoading = false

  deadline = new Date().getTime() + 600000

  timeoutId = 0

  @Emit('updatedAdminList')
  updatedAdminList(admins:Array<BoardAdmin>):Array<BoardAdmin> {
    this.adminLink = null
    return admins
  }

  checkAddAdminComplete(reqBody: {link?: string, token?: string, complete?: boolean, admins?: BoardAdmin[]}): void {
    BoardsApi.addBoardAdminByLink('tg', {
      board_key: this.boardId,
      permissions: this.permissions,
      channel_ids: this.channelIds,
      token: reqBody.token
    })
      .then((response) => {
        if (response.complete && response.admins) {
          this.updatedAdminList(response.admins)

          successNotification()
        } else {
          this.timeoutId = setTimeout(() => {
            this.checkAddAdminComplete(reqBody)
          }, 2000)
        }
      })
      .catch(errorNotification)
  }

  onCountdownFinish(): void {
    this.adminLink = null
    this.$emit('countdownFinish')
  }

  getLink(): void {
    this.getLinkButtonLoading = true

    BoardsApi.addBoardAdminByLink('tg', {
      board_key: this.boardId,
      permissions: this.permissions,
      channel_ids: this.channelIds
    })
      .then((response) => {
        if (response.link) {
          this.adminLink = response.link
        }

        this.checkAddAdminComplete(response)

        this.getLinkButtonLoading = false
      })
      .catch(errorNotification)
      .finally(() => {
        this.getLinkButtonLoading = false
      })
  }

  destroyed():void {
    clearTimeout(this.timeoutId)
  }
}
