




























































import BotLicenseButton from './BotLicenseButton.vue'

import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import FreeLicenseActivationAlert from '@/components/FreeLicenseActivationAlert.vue'

@Component({
  components: {
    FreeLicenseActivationAlert,
    AListCustom,
    AListCustomItem,
    BotLicenseButton,
    HighlightAnchor
  },
})
export default class BoardLicenseInformation extends Vue {
  @Prop() botLicenseTime!:string

  get licenseExpireDate():string {
    return moment(this.botLicenseTime).format('L, HH:mm')
  }

  get boardLicenseTypeTitle():string {
    if (this.$store.getters.hasBoardLicense) {
      return this.$store.getters.boardLicenseType
    } else {
      return '-'
    }
  }

  get botLicenseStatus() {
    if (this.$store.getters.hasBoardLicense) {
      return this.$t('license_active').toString()
    } else if (this.$store.getters.boardLicenseExpires) {
      return this.$t('license_expires').toString()
    } else {
      return this.$t('license_expired').toString()
    }
  }

  get licenseStatusColor() {
    if (this.$store.getters.hasBoardLicense) {
      return 'text-success'
    } else if (this.$store.getters.boardLicenseExpires) {
      return 'text-warning'
    } else {
      return 'text-danger'
    }
  }
}
