













import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { Location } from 'vue-router'

@Component
export default class BotLicenseButton extends Vue {
  buyConfig: Location = {
    name: 'Buy_board',
    params: {
      [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ]
    }
  }

  get buyButtonType():string {
    if (!this.$store.getters.hasBoardLicense) {
      return 'danger'
    }

    return 'default'
  }

  get buyButtonTitle():string {
    if (!this.$store.getters.hasBoardLicense) {
      return this.$t('button_license_buy').toString()
    }

    return this.$t('button_license_prolongate').toString()
  }

}
